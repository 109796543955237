<template>
  <div>
    <c-tab
      type="horizon"
      :inlineLabel="true"
      :dense="true"
      :height="tabHeight"
      :tabItems="tabItems"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :key="tab.key"
          :popupParam.sync="popupParam"
          :nearAccident.sync="nearAccident"
          :isOld="isOld"
          :isUser="isUser"
          :height="tabHeight"
          :attachInfo1="attachInfo1"
          :attachInfo2="attachInfo2"
          @getDetail="getDetail"
          @closePopup="closePopup"
        />
      </template>
    </c-tab>
  </div>
</template>
<script>
import {
  getLoginUser,
} from '@/utils/auth';
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
export default {
  name: 'nearAccidentDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        iimNearAccidentId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      nearAccident: {
        iimNearAccidentId: '',
        plantCd: '',
        iimNearAccidentNo: '',
        iimNearAccidentName: '',
        iimStatusNearCd: '',
        reportDeptCd: '',
        reportUserId: '',
        reportDt: '',
        iimNearKindCds: '',
        iimGradeCd: null,
        discovererId: '',
        occurrenceDt: '',
        occurrenceLocation: '',
        reviewDeptCd: '',
        weatherCd: 'WC000001',
        emergencyMeasures: '',
        overviewAccidentSituation: '',
        occurrenceModeLargeCd: null,
        occurrenceModeSmallCd: null,
        directCause: '',
        indirectCause: '',
        openning: '',
        injurious: '',
        iimNearDiscernCds: null,
        iimNearFunctionCds: null,
        iimNearFeelCds: null,
        iimNearEquipCds: null,
        iimNearManageCds: null,
        processCd: '',
        frequencyRisk: '',
        strengthRisk: '',
        totalRisk: '',
        ramRiskAssessmentPlanId: '',
        auditScore: null,
        auditOpinion: '',
        bounty: null,
        requestManagerId: '',
        requestFlag: 'N',
        vendorFlag: '',
        approvalRequestStatusCd: '', // 결재관련 결재상태 요청승인
        sysApprovalRequestRequestId: '', // 결재관련 결재요청코드 요청승인
        approvalReviewStatusCd: '', // 결재관련 결재상태 검토승인
        sysApprovalReviewRequestId: '', // 결재관련 결재요청코드 검토승인
        approvalAuditStatusCd: '', // 결재관련 결재상태 심사승인
        sysApprovalAuditRequestId: '', // 결재관련 결재요청코드 심사승인
        preventions: [],
      },
      attachInfo1: {
        isSubmit: '',
        taskClassCd: 'NEAR_FIRST_PICTURE',
        taskKey: '',
      },
      attachInfo2: {
        isSubmit: '',
        taskClassCd: 'NEAR_SECOND_PICTURE',
        taskKey: '',
      },
      tab: 'info',
      editable: true,
      detailUrl: '',
    };
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.iimNearAccidentId)
    },
    tabHeight() {
      return String(this.contentHeight - 60);
    },
    tabItems() {
      let items = [{ name: 'info', icon: 'edit', label: '요청부서', component: () => import(`${'./nearRegisterTab.vue'}`), key: uid() }]
      if (this.nearAccident.iimStatusNearCd === 'ISNC000002') {
        items.push({ name: 'prevention', icon: 'construction', label: '개선부서', component: () => import(`${'./nearCausePrevention.vue'}`), key: uid() })
      } else if (this.nearAccident.iimStatusNearCd === 'ISNC000003' || this.nearAccident.iimStatusNearCd === 'ISNC000004') {
        items.push({ name: 'prevention', icon: 'construction', label: '개선부서', component: () => import(`${'./nearCausePrevention.vue'}`), key: uid() })
        items.push({ name: 'audit', icon: 'gavel', label: '환경안전팀', component: () => import(`${'./nearAudit.vue'}`), key: uid() })
      }
      return items;
    },
    isUser() {
      return getLoginUser() === 'U'
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.iim.accident.near.get.url;
      // code setting
      // list setting  
      this.getDetail();
    },
    getDetail(flag) {   //상세페이지 항목들에 저장된 값들을 가져오는 부분
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.iimNearAccidentId);
        this.$http.type = 'GET'; 
        this.$http.request((_result) => {
          if (!flag) {
            this.$_.extend(this.nearAccident, _result.data);
            // this.$set(this.nearAccident, 'requestFlag', _result.data.requestFlag)
            this.$set(this.attachInfo1, 'taskKey', this.popupParam.iimNearAccidentId)
            this.$set(this.attachInfo2, 'taskKey', this.popupParam.iimNearAccidentId)
            this.$emit('transInfo', _result.data);
          } else if (flag === 'planOpinion') {
            this.$_.extend(this.nearAccident, this.$_.omit(_result.data, ['preventions']));
          } else if (flag === 'prevention') {
            this.$_.extend(this.nearAccident, this.$_.pick(_result.data, ['preventions']));
          }
          
          let tabName = 'info';
          if (this.nearAccident.iimStatusNearCd === 'ISNC000002') {
            tabName = 'prevention';
          } else if (this.nearAccident.iimStatusNearCd === 'ISNC000003' || this.nearAccident.iimStatusNearCd === 'ISNC000004') {
            tabName = 'audit';
          }
          this.$set(this.$data, 'tab', tabName)
        },);
      } else {
        this.$set(this.nearAccident, 'discovererId', this.$store.getters.user.userId) // 발견자
        this.$set(this.nearAccident, 'reportUserId', this.$store.getters.user.userId) // 보고자
        this.$set(this.nearAccident, 'occurrenceDt', this.$comm.getTodayDateTiemMin()) // 발생일시
        this.$set(this.nearAccident, 'reportDt', this.$comm.getTodayDateTiemMin()) // 보고일시
      }
    },
    closePopup(data) {
      this.$emit('closePopup', data);  
    },
  }
};
</script>